<template>
	<div>
		<page-header :title="title" :titleNew="titleNew"></page-header>
		<form @submit.prevent="gravar">
			<div class="modal-body">
				<div class="form-horizontal">
					<vue-element-loading :active="isActive" spinner="bar-fade-scale"
						color="#428BCA"></vue-element-loading>
					<input-form-text textInput="Nome" :required="true" v-model="form.titulo"
						:value="form.titulo"></input-form-text>
					<div class="form-group">
						<label class="col-sm-3 control-label no-padding-right">
						</label>
						
					</div>
					<div class="form-group">
						<label class="col-sm-3 control-label no-padding-right" for="perfil">
							Permissão
						</label>
						<div class="col-sm-6">
							<ul class="submenu">
								<li v-for="(m, i) in form.menu" :Key="i">
									<span v-if="form.menu.length">
										<div class="checkbox">
											<label>
												<input class="ace" type="checkbox" v-model="m.visualizar"
													@click="getCheck(m)" />
												<span class="lbl">{{ m.titulo }}</span>
											</label>
										</div>
										<ul>
											<li v-for="(sub, ks) in m.submenu" :key="ks">
												<div class="checkbox">
													<label>
														<input class="ace" type="checkbox" v-model="sub.visualizar"
															@click="getCheckSub(sub)" />
														<span class="lbl">{{ sub.titulo }}</span>
													</label>
												</div>
												<ul>
													<li v-for="(act, ia) in sub.actions" :key="ia">
														<div class="checkbox">
															<label>
																<input class="ace" type="checkbox" v-model="act.value"
																	@click="getCheckChild(act.value, i, ks, m)" />
																<span class="lbl">{{ act.name }}</span>
															</label>
														</div>
													</li>
												</ul>
											</li>
										</ul>
									</span>
								</li>
							</ul>
						</div>
						<div class="col-sm-3">
							<button class="btn btn-primay btn-sm" @click.prevent="getMenu">Reset</button>
						</div>
					</div>
				</div>
			</div>
			<footer-button :disabled="isActive"></footer-button>
		</form>
	</div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import MenuDefault from '@/modules/menu'
import Permission from '@/mixins/Permission'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
	components: {
		VueElementLoading,
		PageHeader,
		InputFormText,
		FooterButton
	},
	mixins: [Permission],
	data() {
		return {
			title: 'Grupo de Acesso',
			titleNew: '',
			form: {
				titulo: '',
				menu: []
			},
			isActive: false,
		}
	},
	methods: {
		async gravar() {
			this.isActive = true
			const body = {
				titulo: this.form.titulo,
				menu: JSON.stringify(this.form.menu)
			}
			if (this.$route.params.id) {
				await req(`/perfil/${this.$route.params.id}`, 'put', body).catch(response => {
					console.log('error: ' + response)
					erro()
				})
				sucesso('Atualizado...')
				this.$router.push('/admin/grupo-de-acesso')

			} else {
				await req('/perfil', 'post', body).catch(response => {
					console.log('error: ' + response)
					erro()
				})
				sucesso('Atualizado...')
				this.$router.push('/admin/grupo-de-acesso')
			}
			this.isActive = false
		},
		async getDados() {
			const resp = await req(`/perfil/${this.$route.params.id}`).catch(response => {
				console.log('error: ' + response)
				erro()
			})
			this.form.titulo = resp.titulo
			this.form.menu = JSON.parse(resp.menu)
			this.isActive = false
		},
		verifPermissao() {
			if (this.$route.params.id) {
				if (!this.ACTION.e) {
					this.$router.push('/erro')
				}
			} else {
				if (!this.ACTION.c) {
					this.$router.push('/erro')
				}
			}
		},
		getCheck(item) {
			if (item.submenu.length) {
				item.submenu.map(s => {
					s.visualizar = !item.visualizar
					if (s.actions.length) {
						s.actions.map(a => {
							a.value = !item.visualizar
							return a
						})
					}
					return s
				})
			}
		},
		getCheckSub(item) {
			if (item.actions.length) {
				item.actions.map(a => {
					a.value = !item.visualizar
					return a
				})
			}
		},
		getMenu() {
			this.form.menu = JSON.parse(JSON.stringify(MenuDefault))
		}
	},
	mounted() {
		this.form.menu = JSON.parse(JSON.stringify(MenuDefault))
		this.verifPermissao()
		if (this.$route.params.id) {
			this.isActive = true
			this.getDados()
		}
	}
}
</script>

<style lang="stylus" scoped>
ul
	margin-left: 15px
	li 
		margin-left: 15px
		ul
			margin-left: 15px
			li 
				margin-left: 15px
</style>
