import Duvidas from '../pages/Sistema/Duvidas/Menu'

const MenuDefault = [
  {
    titulo: 'Início',
    icone: 'menu-icon fa fa-tachometer',
    action: false,
    visualizar: false,
    submenu: [
      {
        titulo: 'Minha Conta',
        icone: 'menu-icon fa fa-caret-right',
        url: '/inicio/minha-conta',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Editar'
          }
        ]
      }
    ]
  },

  {
    titulo: 'Sistema',
    icone: 'menu-icon fa fa-desktop',
    action: false,
    visualizar: false,
    submenu: [
      {
        titulo: 'Assine',
        icone: 'menu-icon fa fa-caret-right',
        url: '/sistema/assine',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          },
          {
            value: false,
            name: 'Excluir'
          }
        ]
      },
      {
        titulo: 'Cobertura',
        icone: 'menu-icon fa fa-caret-right',
        url: '/sistema/cobertura',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          },
          {
            value: false,
            name: 'Excluir'
          }
        ]
      },
      {
        titulo: 'Contato',
        icone: 'menu-icon fa fa-caret-right',
        url: '/sistema/contato',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          },
          {
            value: false,
            name: 'Excluir'
          }
        ]
      },
      {
        titulo: 'Destaque',
        icone: 'menu-icon fa fa-caret-right',
        url: '/sistema/destaque',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          },
          {
            value: false,
            name: 'Excluir'
          }
        ]
      },
      ...Duvidas,
      {
        titulo: 'Planos',
        icone: 'menu-icon fa fa-caret-right',
        url: '/sistema/planos',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          },
          {
            value: false,
            name: 'Excluir'
          }
        ]
      }
    ]
  },
  {
    titulo: 'Admin',
    icone: 'menu-icon fa fa-cog',
    action: false,
    visualizar: false,
    submenu: [
      {
        titulo: 'Grupo de Acesso',
        icone: 'menu-icon fa fa-caret-right',
        url: '/admin/grupo-de-acesso',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          }
        ]
      },
      {
        titulo: 'Usuários',
        icone: 'menu-icon fa fa-caret-right',
        url: '/admin/usuarios',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          }
        ]
      },
      {
        titulo: 'Status',
        icone: 'menu-icon fa fa-caret-right',
        url: '/admin/status',
        visualizar: false,
        actions: [
          {
            value: false,
            name: 'Cadastrar'
          },
          {
            value: false,
            name: 'Editar'
          }
        ]
      }
    ]
  }
]

export default MenuDefault
